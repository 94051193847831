import { Component } from '@angular/core';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../shared/components';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})

export class AppFooterComponent {
  currentYear: string;

  constructor(private noticeMdlService: NgbModal) {
    const currentYear = moment(new Date()).format('YYYY');
    this.currentYear = currentYear;
  }

  // Opens the Privacy Notice Content in Modal
  openNotice() {
    const noticeModal = this.noticeMdlService.open(ModalComponent, { size: 'lg', centered: true });
    noticeModal.componentInstance.isNoticeModal = true;
  }
}
