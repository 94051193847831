import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SearchService } from '../eligibility-search/service/search.service';
import { Provider } from '../enrollment-forms/models/provider.model';
import { FormsService } from '../enrollment-forms/services';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})

export class AppHeaderComponent implements OnInit, OnDestroy {
  showDateTime = false;
  careType: string;
  providerPhone: string;
  providerExt: string;
  isConfigManager: boolean;
  isloggedIn = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private formsService: FormsService,
    private searchService: SearchService
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isLoggedIn => {
      this.isloggedIn = isLoggedIn;
    });

    // Check for care type
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe((careDetails: Provider) => {
      if (careDetails) {
        this.providerPhone = careDetails.phoneno;
        this.providerExt = careDetails.ext;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  logout() {
    this.searchService.clearSelectedCustomer();
    this.authService.logout();
  }
}
