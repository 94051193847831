import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConversionFormService } from '../../services/conversion-form.service';
import { PlatformSubscriberValidateResponse } from '../../models/platform-subscriber-validate-response';

@Component({
  selector: 'app-conversion-verification',
  templateUrl: './conversion-verification.component.html',
  styleUrls: ['./conversion-verification.component.css']
})
export class ConversionVerificationComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @ViewChild('formOne', { static: false }) form: NgForm;

  conversionForm: FormGroup;
  showVerifyButtonSpinner: boolean;
  verifyResult: any;
  verificationFailed: boolean;
  verificationError: string = null;
  unknownLookupError: boolean;

  errorNotFound = 'Not found';
  errorConverted = 'Converted';
  errorMultipleSites = 'Multiple sites';
  errorUnknown = 'Unknown error';

  careCard: string;
  careName: string;
  providerName: string;
  aliasName: string;
  careVerify: string;
  externalId: string;

  isRepLoggedIn = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private conversionFormsService: ConversionFormService,
    private conversionFormBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isRepLoggedIn => {
      this.isRepLoggedIn = isRepLoggedIn;
    });

    this.conversionFormsService.providerDetails$.pipe(takeUntil(this.destroy$)).subscribe((memberCareType: Provider) => {
      if (memberCareType) {
        this.careCard = memberCareType.cardimage;
        this.careName = memberCareType.carename || memberCareType.name;
        this.providerName = memberCareType.name;
        this.aliasName = memberCareType.affiliateid;
        this.careVerify = this.aliasName ? this.aliasName.toLowerCase() : this.aliasName;
        this.externalId = memberCareType.externalid;
      }
    });

    this.conversionForm = this.conversionFormBuilder.group({
      lastName: ['', [
        Validators.required,
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.minLength(3),
        Validators.maxLength(40)
      ]],
      memberId: ['', [Validators.minLength(3), Validators.pattern(/^[A-Za-z0-9]+$/)]],
      serialNo: ['', [Validators.pattern(/^[A-Za-z0-9-]+$/)]],
      siteId: ['', [Validators.pattern(/^[0-9]+$/)]],
    },
      {
        validators: this.identifierPresentValidator(['memberId', 'serialNo', 'siteId'])
      });

    this.conversionForm.controls.memberId.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.disableIdFieldsIfOtherIdUsed(this.conversionForm.controls);
    });

    this.conversionForm.controls.serialNo.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.disableIdFieldsIfOtherIdUsed(this.conversionForm.controls);
    });

    this.conversionForm.controls.siteId.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.disableIdFieldsIfOtherIdUsed(this.conversionForm.controls);
    });

    this.showVerifyButtonSpinner = false;
  }

  disableIdFieldsIfOtherIdUsed(controls) {
    const memberIdValueSet = controls.memberId.value && controls.memberId.value.trim() !== '';
    const siteIdValueSet = controls.siteId.value && controls.siteId.value.trim() !== '';
    const serialNoValueSet = controls.serialNo.value && controls.serialNo.value.trim() !== '';

    if (memberIdValueSet) {
      controls.siteId.disable();
      controls.serialNo.disable();
    } else if (siteIdValueSet) {
      controls.memberId.disable();
      controls.serialNo.disable();
    } else if (serialNoValueSet) {
      controls.memberId.disable();
      controls.siteId.disable();
    } else {
      controls.memberId.enable();
      controls.siteId.enable();
      controls.serialNo.enable();
    }
  }

  identifierPresentValidator(fields: string[]): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const controls = fields.map(field => formGroup.get(field)).filter(control => control);
      const atLeastOneIdentifierPresent = controls.some(control => control && control.value && control.value.trim() !== '');
      return atLeastOneIdentifierPresent ? null : { identifierRequired: true };
    };
  }

  submitConversionForm() {
    this.showVerifyButtonSpinner = true;

    this.conversionFormsService.formConversion = this.conversionForm;

    this.conversionFormsService.verifyMember$(
      'UHC_FFS',
      this.conversionForm.controls.memberId.value,
      this.conversionForm.controls.siteId.value,
      this.conversionForm.controls.serialNo.value,
      this.conversionForm.controls.lastName.value,
    ).subscribe((response: PlatformSubscriberValidateResponse) => {
      this.conversionFormsService.productInformation$.next({
        systemType: response.systemType.trim(),
        systemFamily: response.systemFamily.trim(),
        hasFallDetection: response.hasFallDetection,
        hasMultipleMobileDevices: response.hasMultipleMobileDevices,
        hasMultipleSubscribers: response.hasMultipleSubscribers
      });
      this.conversionFormsService.siteNumber = response.siteNo;
      this.showVerifyButtonSpinner = false;
      this.nextEvent.emit();
    }, (error: unknown) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 404) {
          this.verificationFailure(this.errorNotFound);
        } else if (error.status === 409) {
          this.verificationFailure(this.errorConverted);
        } else if (error.status === 422) {
          this.verificationFailure(this.errorMultipleSites);
        } else {
          this.verificationFailure(this.errorUnknown);
        }
      }
    });
  }

  verificationFailure(error: string) {
    this.verificationFailed = true;
    this.verificationError = error;
    this.showVerifyButtonSpinner = false;
  }

  showPrevious() {
    this.verificationFailed = false;
    this.verificationError = null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
