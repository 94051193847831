import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';

import { AppConfig, AppConfigValues } from '../shared/services/app-config.service';
import { AwsRumFunctions } from '../shared/aws-rum-functions.service';
import { TraceIdService } from '../enrollment-forms/services/trace-id.service';

const DEFAULT_TIMEOUT = 60000;

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    defaultContentType = 'application/json';
    defaultAcceptType = 'application/json';

    constructor(private appConfig: AppConfig, private traceIdService: TraceIdService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.adjustRequestHeaders(request);

        const startTime = Date.now();

        return next.handle(request).pipe(
            timeout(this.timeoutIfApplicable(request.url)),
            tap(
                (event: any) => {
                    if (event instanceof HttpResponse) {
                        const endTime = Date.now();
                        const durationInMs = endTime - startTime;
                        this.logHttpEvent(request, event, durationInMs);
                    }
                },
                (error: unknown) => {
                    if (error instanceof HttpErrorResponse) {
                        const endTime = Date.now();
                        const durationInMs = endTime - startTime;
                        this.logHttpEvent(request, error, durationInMs);
                    }
                }
            ));
    }

    public adjustRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
        const enrollmentAppId = this.appConfig.config.enrollmentAppId;
        const enrollmentAppSecret = this.appConfig.config.enrollmentAppSecret;

        const appIdHeader = request.headers.has('AppId') ? request.headers.get('AppId') :
            enrollmentAppId ? enrollmentAppId : '';
        const appSecretHeader = request.headers.has('AppSecret') ? request.headers.get('AppSecret') :
            enrollmentAppSecret ? enrollmentAppSecret : '';
        const contentTypeHeader = request.headers.has('Content-Type') ? request.headers.get('Content-Type') :
            this.defaultContentType ? this.defaultContentType : '';

        // Do not break into multiple statements; this can result in headers being lost due to lazy loading.
        // There is a slim chance that existing headers may be overwritten for the same reason.
        const updatedHeaders = request.headers
            .set('Accept', this.defaultAcceptType)
            .set('AppId', appIdHeader)
            .set('AppSecret', appSecretHeader)
            .set('Content-Type', contentTypeHeader)
            .set('Trace-ID', this.traceIdService.getExistingId());

        return request.clone({ headers: updatedHeaders });
    }

    private logHttpEvent(request: HttpRequest<any>, event: HttpResponse<any> | HttpErrorResponse, durationInMs: number) {
        if (request.body instanceof Object) {
            Object.keys(request.body).filter(x => x.toLowerCase().includes('password')).forEach(x => request.body[x] = '********');
        }

        const eventData = {
            url: request.urlWithParams,
            method: request.method,
            durationInMs,
            body: request.body,
            responseStatus: event.status,
            responseStatusText: event.statusText,
            responseBody: event instanceof HttpRequest ? JSON.stringify(event.body).substring(0, 16000) : null,
            responseMessage: event instanceof HttpErrorResponse ? event.message : null,
            traceId: this.traceIdService.getExistingId()
        };

        const loggedError: Error = new Error(JSON.stringify(eventData));

        if (this.appConfig.config.debug) {
            if (event instanceof HttpResponse) {
                console.log(eventData);
            } else if (event instanceof HttpErrorResponse) {
                console.error(loggedError);
            }
        }

        if (this.httpEventShouldBeLoggedToRum(request, event, durationInMs)) {
            AwsRumFunctions.callCwr('recordError', loggedError);
        }
    }

    private httpEventShouldBeLoggedToRum(
        request: HttpRequest<any>,
        event: HttpResponse<any> | HttpErrorResponse,
        durationInMs: number
    ): boolean {
        const config: AppConfigValues = this.appConfig.config;

        if (event instanceof HttpErrorResponse) {
            return true;
        } else if (config.logAllHttpEvents) {
            return true;
        } else if (config.logAllHttpEventsExceedingMilliseconds && durationInMs >= config.logAllHttpEventsExceedingMilliseconds) {
            return true;
        } else if (config.logAddressApi && request.url.includes(config.addressApi)) {
            return true;
        } else if (config.logEnrollmentApiSearch && request.url.includes(config.enrollmentApiSearchBase)) {
            return true;
        } else if (config.logPlatformApi && request.url.includes(config.platformApi)) {
            return true;
        } else if (config.logEnrollmentApi && request.url.includes(config.enrollmentApi)) {
            // logEnrollmentApiSearch overrides logEnrollmentApi where applicable
            return config.logEnrollmentApiSearch !== false || !request.url.includes(config.enrollmentApiSearchBase);
        } else if (config.logEnrollmentApiSearch === false && request.url.includes(config.enrollmentApiSearchBase)) {
            // List separately from logEnrollmentApi in case there are any environments where
            // enrollmentApiSearchBase does not contain enrollmentApi
            return false;
        } else {
            return false;
        }
    }

    private timeoutIfApplicable(requestUrl: string): number {
        if (this.appConfig.config.addressApiStreetAddress &&
            this.appConfig.config.addressApiStreetAddressTimeout &&
            requestUrl === this.appConfig.config.addressApiStreetAddress) {
            return this.appConfig.config.addressApiStreetAddressTimeout;
        } else {
            return DEFAULT_TIMEOUT;
        }
    }
}
